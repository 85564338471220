<template>
  <v-container class="mx-lg-6 mt-7">
    <v-row>
      <v-spacer/>
      <v-col sm="11" xl="9">
        <v-row class="my-2">
          <v-col cols="12">
            <span
                style="font-weight: bolder; font-size: larger">Geschützter Zugang mit erweitertem Produkt- und Funktionsangebot</span>
          </v-col>
        </v-row>
      </v-col>
      <v-spacer/>
    </v-row>
    <v-row>
      <v-spacer/>
      <v-col sm="11" xl="10">
        <v-row>
          <v-col cols="6" sm="4" xl="2">
            <v-form class="pl-2" ref="vup" action="/ASmobile/GISConnector"
                    method="post">
              <v-text-field v-show="false" value="vup"
                            name="appid"
                            type="text"
              />
              <v-text-field
                  label="Benutzername"
                  name="username"
                  type="text"
                  dense
              />
              <v-text-field
                  name="password"
                  label="Passwort"
                  :type="hidePassword ? 'password' : 'text'"
                  dense>
                <template v-slot:append>
                  <v-tooltip
                      bottom
                  >
                    <template v-slot:activator="{ on }">
                      <v-icon style="font-size: 20px" v-on="on" @click="hidePassword = !hidePassword">
                        {{ hidePassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline' }}
                      </v-icon>
                    </template>
                    {{ hidePassword ? 'Passwort anzeigen' : 'Passwort ausblenden' }}
                  </v-tooltip>
                </template>
              </v-text-field>
              <v-row>
                <v-col>
                  <v-btn color="green" height="30" dark class="mt-4"
                         type="submit">
                    <v-icon
                        left
                    >mdi-cube-outline
                    </v-icon>
                    <span style="font-size: larger">Portal-Anwendung öffnen</span>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-btn color="#3C506E" height="30" dark to="/bestellformular/vermessungsstellen"
                         usergroupSelected="vermessungsstellen">
                    <v-icon
                        left
                    >mdi-human-handsup
                    </v-icon>
                    <span style="font-size: larger">Bestellformular ohne Kartennutzung (barrierefreier)</span>
                  </v-btn>
                </v-col>
              </v-row>

            </v-form>
          </v-col>
          <v-col class="ml-8">

            <v-row>
              <v-col>
                <v-btn color="dimgrey" style="width: 200px" height="30"
                       type="submit" target="_blank" href="/CustomerAdministration_VUP/UserAdmin/Login.jsp">
                  <v-icon
                      left
                  >mdi-account-group
                  </v-icon>
                  <span style="font-size: larger">zur Nutzerverwaltung</span>
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn color="dimgrey" style="width: 200px" height="30"
                       type="submit" target="_blank" href="/ASmobile/admin/requestresetpassword.html">
                  <v-icon
                      left
                  >mdi-lock-question
                  </v-icon>
                  <span style="font-size: larger">Passwort vergessen</span>
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn color="dimgrey" style="width: 200px" height="30"
                       type="submit" target="_blank" href="/ASmobile/admin/changepassword.html">
                  <v-icon
                      left
                  >mdi-lock
                  </v-icon>
                  <span style="font-size: larger">Passwort ändern</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

      </v-col>
    </v-row>
    <v-row class="mb-4" justify="center">
      <v-col sm="11" xl="9">
        <v-row class="my-2">
          <InfoMap :messages="messages" typeOfAccess="vermessungsstellen" class="mb-2"/>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import axios from "axios";
import InfoMap from "@/components/InfoMap";
import VListProductInfo from "@/components/VListProductInfo";
import {API} from '@/config';

export default {

  components: {
    InfoMap,
    VListProductInfo
  },
  data() {
    return {
      hidePassword: true,
      showProductInfo: false,
      showAvailability: false,
      messages: [],
    }
  },
  methods: {
    getMessages() {
      axios.post(`${API.url}${API.getMessages}`).then((response) => {
        this.messages = response.data.features;
      }).catch(function () {
      })

    },
  },
  mounted() {
    this.getMessages();
  },
}
</script>
